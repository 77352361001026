// export const URL = "http://localhost:8765/";
// export const URL = 'https://test.arabicskillstest-uae.com/';

export const URL = 'https://demotest.arabicskillstest-uae.com/';

export const AUTHENTICATE_URL = URL + "api/users/token.json";
export const SPEAKING_URL = URL + "api/speakings/getByLoa/";
export const READING_URL = URL + "api/Readings/";
export const WRITING_URL = URL + "api/Writings/getByLoa/";
export const LISTENING_URL = URL + "api/Listenings/";

export const SUBMIT_URL = URL + 'api/users/submit.json';
// export const SUBMIT_URL = 'https://react-5428c-default-rtdb.firebaseio.com/forms.json';
